import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/monitor',
    name: '商圈监控-硖石景区智慧商圈',
    component: () => import('../views/Home.vue'),
    meta: { title: '平台预警-硖石景区智慧商圈' }
  },
  {
    path: '/business',
    name: '商圈展示',
    component: () => import('../views/Business.vue'),
    meta: { title: '商圈展示-硖石景区智慧商圈' }
  },
  {
    path: '/equipment',
    name: '智慧设备管理',
    component: () => import('../views/Equipment.vue'),
    meta: { title: '平台预警-硖石景区智慧商圈' }
  },
  {
    path: '/jingdianguanli',
    name: '线上运营营销分析-硖石景区智慧商圈',
    component: () => import('../views/jingdianguanli.vue'),
    meta: { title: '线上运营营销分析-硖石景区智慧商圈' }
  },
  {
    path: '/shangpuguanli',
    name: '营销分析-硖石景区智慧商圈',
    component: () => import('../views/shangPuGuanLi.vue'),
    meta: { title: '营销分析-硖石景区智慧商圈' }
  },
  {
    path: '/',
    name: '客流分析-硖石景区智慧商圈',
    component: () => import('../views/renLiuFenXi.vue'),
    meta: { title: '客流分析-硖石景区智慧商圈' }
  },
  {
    path: '/control',
    name: '控制终端',
    component: () => import('../views/control.vue'),
    meta: { layout: 'control' }
  },
  {
    path: '/overview',
    name: '5D可视化大数据仓',
    component: () => import('../views/Overview.vue'),
    meta: { title: '5D可视化大数据仓' }
  },
  {
    path: '/fireworks',
    name: '大屏互动',
    component: () => import('../views/fireworks.vue'),
    meta: { title: '大屏互动', layout: 'firework' }
  }
]

const router = new VueRouter({
  // mode: 'history',
  routes
})

router.beforeEach((to, _, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
